import constants from '@/utils/constants'

const ACCESS_TOKEN = 'rcc'
const REFRESH_TOKEN = 're-rcc'

function b64DecodeUnicode (str) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(str), c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))
}

function parseJwt (token) {
  try {
    return JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')))
  }
  catch {
    return {}
  }
}

// TODO: Redo to normal Vuex state
export default {
  axios: null, // init from main.js
  apiMethods: null, // init from main.js

  user: {
    axios: null, // init from main.js
    apiMethods: null, // init from main.js

    access_token: null,
    _refresh: null,

    _id: null,
    _login: null,
    _fio: null,
    _role: null,
    _supplierData: null,

    async init () {
      if (!this.access_token) {
        this._refresh = localStorage.getItem(REFRESH_TOKEN)
        this.access_token = localStorage.getItem(ACCESS_TOKEN)
        this.setAxiosHeader()
      }

      if (this.access_token) {
        const data = parseJwt(this.access_token)
        this._id = data.id
        this._login = data.login
        this._fio = data.fio
        this._role = data.role

        this.getSupplierData()
      }
    },

    async getSupplierData() {
      if (this._role === constants.users.supplier) {
        try {
          const data = await this.apiMethods.supplierProfile.get()
          this._supplierData = data
        } catch (error) {
          console.error(error)
        }
      }
    },

    logout () {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      window.location = '/login'
    },

    setAxiosHeader () {
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.access_token
    },

    set_tokens (access, refresh) {
      this.access_token = access
      localStorage.setItem(ACCESS_TOKEN, access)
      this._refresh = refresh
      localStorage.setItem(REFRESH_TOKEN, refresh)
      this.setAxiosHeader()
      this.init()
    },

    refresh () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.axios.post('/admin/refresh_token', { refresh: this._refresh })
          .then(response => {
            const { access, refresh } = response.data
            this.set_tokens(access, refresh)
            resolve(this.access_token)
          })
          .catch(err => {
            resolve(err)
          })
      })
    },

    get id () {
      if (!this._id) {
        this.init()
      }
      return this._id
    },

    get login () {
      if (this._login == null) {
        this.init()
      }
      return this._login
    },

    set login (val) {
      this._login = val
    },

    get fio () {
      if (this._fio == null) {
        this.init()
      }
      return this._fio
    },

    set fio (val) {
      this._fio = val
    },

    get role () {
      if (!this._role) {
        this.init()
      }
      return this._role
    },

    get supplierData () {
      return this._supplierData
    },

    set supplierData (val) {
      this._supplierData = val
    }
  },

  breadcrumbs: [],
  staticTop: 0,
  refundChanges: 0,
  notifications: [],
  returnsFilters: [],
  returnsCurrentPage: 0,
  isTableEditingEnabled: false,
  isReturnsManagementModeEnabled: false,

  // TODO: Moved here to save memory. Think about how to refactor
  modifyCellFunctions: {
    width: (_this, modifier) => { _this.$refs.cellRef.style.width = modifier.value },
    'max-width': (_this, modifier) => { _this.$refs.cellRef.style.maxWidth = modifier.value },
    'min-width': (_this, modifier) => { _this.$refs.cellRef.style.minWidth = modifier.value },
    edit: _this => { _this.isEditAction = true },
    tooltip: (_this, modifier) => {
      _this.isTooltip = true
      _this.tooltipCustomValue = modifier.value || ''
    },
    icon: (_this, modifier) => {
      if (modifier.isRequired && !_this.realValue) {
        return
      }

      _this.icon = modifier.value
    },
    color: (_this, modifier) => { _this.cellClasses.push('rcc-cell' + COLOR_CLASSES[modifier.value]) },
    isWarning: (_this, modifier) => {
      if (modifier.value) {
        _this.cellClasses.push('rcc-cell_is-warning')
      }
    },
    align: (_this, modifier) => { _this.cellClasses.push('rcc-cell' + ALIGN_CLASSES[modifier.value]) },
    'pre-wrap': _this => {
      _this.$refs.cellRef.querySelector('.rcc-cell__content').classList.add('rcc-cell__content_pre-wrap')
    },
    stringTruncation: _this => {
      _this.$refs.cellRef.querySelector('.rcc-cell__content').classList.add('rcc-cell__content_string-truncation')
    },
    filler: (_this, modifier) => { _this.filler = modifier.value }
  }
}

const COLOR_CLASSES = {
  'success': '_success',
  'warning': '_warning',
  'error': '_error'
}

const ALIGN_CLASSES = {
  'center': '_center'
}
