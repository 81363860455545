export default {
  methods: {
    getReturns(params) {
      return this.$api.get('admin/return', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getReturn(id) {
      return this.$api.get('admin/return/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createReturn(params) {
      return this.$api.post('admin/return/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateReturn(id, params) {
      return this.$api.post('admin/return/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getExcel(params) {
      return this.$api.get('admin/return/xls', params, { responseType: 'blob' })
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getChanges(params) {
      return this.$api.get('admin/return/changed', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    confirmChanges(id) {
      return this.$api.post('admin/return/' + id + '/confirm')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    cancelReturns(returns) {
      return this.$api.post('admin/return-tasks/cancel', { order_ids: returns })
        .then(data => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    removeReturns(returns) {
      return this.$api.post('admin/return-tasks/delete', { order_ids: returns })
        .then(data => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    changeTsNumbers(params) {
      return this.$api.post('admin/return-tasks/edit', params)
        .then(data => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    changeReturnStatuses(params) {
      return this.$api.post('admin/return-tasks/change-internal-status', params)
        .then(data => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

export function returns(api) {
  return {
    update: (id, params) => {
      return api.post('admin/return/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    columns: () => {
      return api.get('admin/return/columns')
        .then(({ data: { columns } }) => {
          return columns
        })
    },

    saveColumnsSettings: columns => {
      return api.post('admin/return/columns', columns)
        .then(({ data }) => {
          return data
        })
    }
  }
}
