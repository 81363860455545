<template>
  <v-app id="inspire" :class="classes">
    <rcc-sidebar :isOpen="isDrawerOpen"/>

    <rcc-header ref="headerRef" @clickToDrawerIcon="isDrawerOpen = !isDrawerOpen" />

    <v-main>
      <v-container id="main-content">
        <router-view />
      </v-container>
    </v-main>
    <rcc-loading-overlay />
    <rcc-snackbar />
    <rcc-confirm-dialog />
  </v-app>
</template>

<script>
import RccHeader from '@/components/header/rcc-header'
import RccSidebar from '@/components/sidebar/rcc-sidebar'
import RccSnackbar from '@/components/snackbar/rcc-snackbar'
import RccLoadingOverlay from '@/components/loading-overlay'
import RccConfirmDialog from  '@/components/dialogs/global-confirm'

const NOTIFICATIONS_INTERVAL = 60000

export default {
  components: {
    RccHeader,
    RccSidebar,
    RccSnackbar,
    RccLoadingOverlay,
    RccConfirmDialog
  },

  data() {
    return {
      isDrawerOpen: true,
      notificationsInterval: null
    }
  },

  mounted() {
    this.$store.staticTop = this.$refs.headerRef.$el.offsetHeight

    if (this.$store.user.id && !this.isSupplierRole) {
      this.loadNotifications()
      this.notificationsInterval = setInterval(this.loadNotifications, NOTIFICATIONS_INTERVAL)
    }
  },

  computed: {
    classes() {
      return [
        'app',
        this.$store.isTableEditingEnabled && 'app_is-no-pointers-events'
      ]
    },

    isSupplierRole() {
      return this.$store.user.role === this.$constants.users.supplier
    }
  },

  methods: {
    loadNotifications() {
      this.$apiMethods.notifications.get().then(items => {
        if (items) {
          this.$store.notifications = items.concat(this.$store.notifications)
        }
      })
    }
  },

  destroyed() {
    clearInterval(this.notificationsInterval)
  }
}
</script>

<style lang="scss" scoped>
.app {
  &_is-no-pointers-events {
    pointer-events: none;
  }
}

#main-content {
  width: 100%;
  height: 100%;
  background: $main-background;
  border-radius: 30px;
  padding: 0;

  @media screen and (max-width: 1904px) {
    max-width: 100%;
    margin: 0;
  }
}
</style>
